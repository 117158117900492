.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

div.topten {
  max-width: 200px;
}

div.card {
  /* TODO: Turn this off and do a better job of controller card widths */
  max-width: 250px;
  margin: 10px;
}

.ui.statistic .label {
  color: gray !important;
}

body * {
  /* text-transform: lowercase !important; */
}