.feature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f9f9f9;
    padding: 40px;
    height: 100%;
    max-width: 512px;
}

.feature-container-alternate {
    background-color: #2d2d2d;
}

.feature-container > div {
    line-height: normal;
}

.feature-title {
    font-family: 'Anton', sans-serif;
    font-size: 54px;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.feature-title-alternate {
    color: #f9f9f9
}

.feature-description {
    font-size: 16px;
    color: #888888;
    text-align: center;
}

.feature-image {
    margin-top: 20px;
}