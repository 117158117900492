.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
}

.home-banner {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 50px 100px;
}

.home-banner-jumbotron {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.home-banner-text {
  color: white;
  font-family: 'Anton', sans-serif;
  font-size: 6vw;
  line-height: normal;
  letter-spacing: 4px;
  text-align: center;
  padding-right: 10px
}

.home-banner-image {
  max-width: 100%;
  object-fit: contain;
}

.home-banner-download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home-download-button {
  margin: 0px 20px !important;
  background-color: white !important;
  color: black !important;
  margin: 0px 10px;
}

.home-features {
  display: grid;
  grid-template-columns: auto auto; /* Two columns */
  grid-template-rows: auto auto; /* Two rows */
  gap: 20px; 
  padding: 20px; 
}

@media (max-width: 1024px) {
  .home-features {
    grid-template-columns: 1fr;
  }

  .home-banner-jumbotron {
    flex-direction: column;
    align-items: center;
  }

  .home-banner-text {
    font-size: 5vw;
    padding-bottom: 20px;
    padding-right: 0px
  }
}

@media (max-width: 768px) {
  .home-banner {
    padding: 50px 20px;
  }

  .home-banner-image {
    max-width: 75%;
    object-fit: contain;
  }

  .home-banner-text {
    font-size: 8vw;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}

.home-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
    width: 90%;
    padding: 10px;
    font-size: small;
}

.falling-object {
  animation: fall .55s ease forwards;
}

.shaking-object {
  animation: shake 0.55s ease forwards;
}

@keyframes fall {
  0% {
    transform: scale(5);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(20px);
  }

  60% {
    transform: translateX(-15px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(-5px);
  }
}