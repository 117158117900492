.badge {
    border-radius: 4px;
    user-select: none;
    flex-grow: 0;
}

.badge-text {
    padding: 4px 8px;
    text-transform: uppercase !important;
    color: white;
    font-size: x-small;
    font-weight: 900;

}