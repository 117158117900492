.GameCard-extra {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.GameCard-extra-badges {
    justify-content: right;
    display: flex;
    flex-direction: row;
}

.GameCard-extra-badges:last-child {
    margin-left: auto;
}

.GameCard-extra-badges>a:not(:last-child) {
    margin-right: 4px;
}