.ui.battle-net.button {
    background-color: #0e86ca;
    opacity: .8;
    color: #fff;
    text-shadow: none;
    background-image: none;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.battle-net.button:hover {
    opacity: 1;
}

.ui.discord.button {
    background-color: #7289da;
    opacity: .8;
    color: #fff;
    text-shadow: none;
    background-image: none;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.discord.button:hover {
    opacity: 1;
}

.ui.twitch.button {
    background-color: #9147ff;
    opacity: .8;
    color: #fff;
    text-shadow: none;
    background-image: none;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.twitch.button:hover {
    opacity: 1;
}

.ui.steam.button {
    background-color: #799905;
    opacity: .8;
    color: #fff;
    text-shadow: none;
    background-image: none;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
}

.ui.steam.button:hover {
    opacity: 1;
}

.faIcon {
    opacity: .8;
    vertical-align: middle;
    line-height: 1;
    height: .85714286em;
    margin: 0 .42857143em 0 -.21428571em;
}